<template>
  <div id="container">
    <main id="content-pane">
      <router-view :key="key" />
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Admin",
  computed: {
    ...mapState({
      routing: state => state.ui.routing
    }),
    key() {
      let key = null;
      let matched = this.$_.clone(this.$route.matched);
      this.$_.each(matched.reverse(), route => {
        if (this.$_.has(route, "meta.key")) {
          key = route.meta.key(this.$route.params);
          return false;
        }
      });
      return key;
    }
  },
  mounted() {
    this.$emit("mounted");
  }
};
</script>

<style scoped lang="scss">
@import "~@sass/bulma/custom-variables";

#container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0;
  transition-duration: 0.2s;
  min-width: 20em;
}

#content-pane {
  grid-column: 1 / span 2;
  height: 100vh;
  background: $light;
  position: relative;
  @include is-scrollable;
  & > .custom-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
